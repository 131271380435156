import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Omnifood from "../components/Images/Omnifood"
import CheshTech from "../components/Images/CheshTech"
import "./websites.styles.scss"
import { Helmet } from "react-helmet"

const Websites = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>William Cheshier</title>
        <link rel="canonical" href="https://williamcheshier.com/portfolio" />
      </Helmet>
      <div className="web-wrapper">
        <h1 className="web-header">Websites</h1>
        <div className="web-row">
          <div className="web-column">
            <a
              href="https://cheshtech.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CheshTech />
            </a>
          </div>
          <div className="web-text-column">
            <h1 className="web-title">CheshTech</h1>
            <p className="web-text">
              My freelance company website designed using bootstrap, styled
              components, gatsby, react, and graphql. A website offering a
              simple, but neat look. Exciting opportunity to help small
              businesses with basic website needs in my free time. Want to do
              some work together? Check out the site!
            </p>
            <p className="web-text">Completed: April 2020</p>
            <p className="web-text">
              Tech: React | Styled Components | Graphql | Gatsby| Bootstrap{" "}
            </p>
            <div className="web-link-row">
              <a
                className="web-links"
                href="https://cheshtech.com/"
                target="_blank"
              >
                Live application
              </a>
              <a
                className="web-links"
                href="https://github.com/wchesh24/william-website"
                target="_blank"
              >
                Repo
              </a>
            </div>
          </div>
        </div>
        <div className="web-row">
          <div className="web-column">
            <a
              href="https://omnifood-website-william.herokuapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Omnifood className="web-img" />
            </a>
          </div>
          <div className="web-text-column">
            <h1 className="web-title">Omnifood</h1>
            <p className="web-text">
              The first complete website I made using HTML5, CSS3, and some
              basic JavaScript/JQuery. A website showcasing a restaurant
              displayed on a neat and clean one page format. I particularly like
              the subtle but effective animations on this website.
            </p>
            <p className="web-text">Completed: September 2019</p>
            <p className="web-text">
              Tech: HTML5 | CSS3 | JavaScript | jQuery{" "}
            </p>
            <div className="web-link-row">
              <a
                className="web-links"
                href="https://omnifood-website-william.herokuapp.com/"
                target="_blank"
              >
                Live application
              </a>
              <a
                className="web-links"
                href="https://github.com/wchesh24/wchesh24.github.io"
                target="_blank"
              >
                Repo
              </a>
            </div>
          </div>
        </div>
        <Link className="application-link" to="/applications">
          <h2>Applications</h2>
        </Link>
      </div>
    </Layout>
  )
}

export default Websites
